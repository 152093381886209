<template>
  <div>
    <router-link class="come-back" to="/admin/connect_contacts">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">Редактирование контакта</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createPhoneCertificate" ref="createPhoneCertificate">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Должность<span>*</span></p>
          <input required placeholder="Введите должность" v-model="contactsPosition.position" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>ФИО<span>*</span></p>
          <input required placeholder="Введите ФИО" v-model="contactsPosition.full_name" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Должность (Англ)<span>*</span></p>
          <input required placeholder="Введите должность (Англ)" v-model="contactsPositionEng.position" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>ФИО (Англ)<span>*</span></p>
          <input required placeholder="Введите ФИО (Англ)" v-model="contactsPositionEng.full_name" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Телефон<span>*</span></p>
          <input required placeholder="Введите телефон" v-model="phoneCertificate[0]" type="phone" name="workDay" class="input blue">
        </div>

        <div v-for="(contactBlock, index) in phoneCertificate" :key="`contactBlock${index}`" class="contacts__block">
            <header v-if="index!==0">
                <h6> Блок графика работы №{{ index+1 }}</h6>
                <img src="/assets/icons/close.svg" @click="deleteContactBlock(index)">
            </header>
            <div class="content-sb" v-if="index!==0">
                <p>Телефон<span>*</span></p>
                <input required placeholder="Введите телефон" v-model="phoneCertificate[index]" type="phone" name="workDay" class="input blue">
            </div>
        </div>

        <div class="btn-blue" @click="addBlock()">
          Добавить телефон
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/connect_contacts" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreatePhoneCertificate',

  data () {
    return {
      phoneCertificate: [],
      contactsPosition: {
        full_name: '',
        position: ''
      },
      contactsPositionEng: {
        full_name: '',
        position: ''
      }
    }
  },

  watch: {
    '$store.getters.getPhoneCertificateAdmission' () {
      const dataFromServer = this.$store.getters.getPhoneCertificateAdmission
      this.contactsPosition.full_name = dataFromServer.contacts[0].full_name
      this.contactsPosition.position = dataFromServer.contacts[0].position
      this.contactsPositionEng.full_name = dataFromServer.contacts_eng[0].full_name
      this.contactsPositionEng.position = dataFromServer.contacts_eng[0].position
      this.phoneCertificate = dataFromServer.phones
    }
  },

  created () {
    this.$store.dispatch('setPhoneCertificateAdmission')
  },

  methods: {
    createPhoneCertificate () {
      const formData = new FormData()
      formData.append('contacts', JSON.stringify([this.contactsPosition]))
      formData.append('contacts_eng', JSON.stringify([this.contactsPositionEng]))
      formData.append('phones', JSON.stringify(this.phoneCertificate))
      this.$store.dispatch('editCertificateAdmission', formData)
    },

    addBlock () {
      this.phoneCertificate.push('')
    },

    deleteContactBlock (index) {
      this.phoneCertificate.splice(index, 1)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
